import { createThemes } from 'tw-colors'

/** @type {import('tailwindcss').Config} */
export default {
  darkMode: ['class'],
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
    '../client/src/**/*.{ts,tsx}',
    './app/**/*.{ts,tsx}',
    './src/**/*.{ts,tsx}',
    '../end-users/src/**/*.{ts,tsx}',
    '../end-users/src/components/**/*.{ts,tsx}',
  ],
  prefix: '',
  theme: {
    satisfies: ['theme-theme1', 'theme-theme2', 'bg-theme1', 'bg-theme2'],
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    backgroundImage: {
      theme1: 'radial-gradient(at bottom right , #2B6B9F,#73CCD8,#FFFFFF 40%)',
      theme2: 'url("/public/images/background-violet.svg")',
    },
    extend: {
      colors: {
        border: 'hsl(var(--border))',
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        foreground: 'hsl(var(--foreground))',
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))',
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))',
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))',
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))',
        },
        secondaryShades: '#F3EAF7',
        primary: '#1812FF',
        secondary: '#490091',
        accent: '#3CDC4B',
        marine: {
          800: '#091185',
          700: '#1812FF',
          500: '#3730FF',
          300: '#5C50FF',
          100: '#816FFF',
          80: '#A38FFF',
          30: '#C7D2FE',
        },
        violet: {
          800: '#490091',
          600: '#5C1B9C',
          400: '#7438A9',
          200: '#8E58B7',
          90: '#A778C4',
          30: '#E3D1EB',
        },
        green: {
          700: '#12340C',
          500: '#174713',
          300: '#237524',
          90: '#30AD38',
          60: '#3CDC4B',
          50: '#66E373',
        },
        black: {
          1: '#000000',
          2: '#1D1D1D',
          3: '#282828',
          4: '#FFFFFF',
        },
        ui1: '#ECEEE7',
        gray: {
          1: '#333333',
          2: '#4F4F4F',
          3: '#828282',
          4: '#BDBDBD',
          5: '#E0E0E0',
          6: '#374151',
          7: '#E5E7EB',
          8: '#FAFAFA',
          9: '#111827',
          10: '#6B7280',
        },
        twgrey: '#f9fafb',
        pink: '#FF44A9',
        customYellow: '#F5BD60',
        customRed: '#ED655A',
      },
      fontSize: {
        '55xl': '3.5rem',
      },
      fontFamily: {
        serif: 'Space Grotesk',
        sans: 'Inter',
      },
      screens: {
        fold: '279px',
        mobile: '339px',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
    },
  },
  plugins: [
    createThemes({
      theme1: {
        'theme-primary': '#000000',
        secondary: 'darkblue',
        brand: '#F3F3F3',
        'theme-button': '#000000',
        'theme-button-hover': '#E47654',
        'theme-button-border-hover': '#E47654',
        'theme-text': '#000000',
        'theme-header-text': '#000000',
      },
      theme2: {
        'theme-primary': '#000000',
        secondary: 'green',
        brand: 'blue',
        'theme-button': '#000000',
        'theme-button-hover': '#cccccc',
        'theme-button-border-hover': '#cccccc',
        'theme-text': '#000000',
        'theme-header-text': '#ffffff',
      },
    }),
  ],
}
