import { z } from 'zod'

enum Gender {
  Man = 'man',
  Woman = 'woman',
  Different = 'different',
}
const validatePostcodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
const validateHouseNumberRegex = /^[1-9]\d*(?: ?(?:[/-] ?\d+?))?$/dm
const validatePhoneNumberRegex = /^\d{10}$/

export const conditionalField = (isRequired: boolean) =>
  isRequired
    ? z
        .string({
          errorMap: () => {
            return { message: 'required' }
          },
        })
        .min(1, 'required')
    : z.string().optional().nullable()

export const validateGender = (isRequired: boolean) =>
  isRequired
    ? z.nativeEnum(Gender, {
        errorMap: () => {
          return { message: 'required' }
        },
      })
    : z.nativeEnum(Gender).optional().nullable()

export const validatePostcode = (isRequired: boolean) =>
  conditionalField(isRequired).and(
    z
      .string()
      .optional()
      .refine(
        (value) =>
          value && value?.length > 0 ? validatePostcodeRegex.test(value) : true,
        'invalid_postcode',
      ),
  )

export const validateHouseNumber = (isRequired: boolean) =>
  conditionalField(isRequired).and(
    z
      .string()
      .optional()
      .refine(
        (value) =>
          value && value?.length > 0
            ? validateHouseNumberRegex.test(value)
            : true,
        'invalid_house_number',
      ),
  )

const optionalEmailInput = (schema: z.ZodString) =>
  z
    .union([z.string(), z.undefined()])
    .refine((val) => !val || schema.safeParse(val).success, {
      message: 'invalid_email',
    })

export const validateEmail = (isRequired: boolean) =>
  isRequired
    ? conditionalField(isRequired).and(
        z.string().email({ message: 'invalid_email' }),
      )
    : optionalEmailInput(z.string().email())

export const validatePhoneNumber = (isRequired: boolean) =>
  conditionalField(isRequired).and(
    z
      .string()
      .optional()
      .nullable()
      .refine(
        (value) =>
          value && value?.length > 0
            ? validatePhoneNumberRegex.test(value)
            : true,
        'invalid_phone_number',
      ),
  )
