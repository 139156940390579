import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ProgressBar } from './progress-bar'
import { useLazyQuery } from '@apollo/client'
import GET_CAMPAIGN from '@/graphql/campaign/queries/get'
import Loading from './loading'
import { useEffect, useMemo } from 'react'
import { addHours } from 'date-fns'
import cx from 'classnames'

export const CampaignLayout: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const location = useLocation()
  const { pathname } = location

  const [getAsyncCampaign, { loading, data }] = useLazyQuery(GET_CAMPAIGN, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (
      new Date() > addHours(new Date(data?.campaign?.startDate), 24) &&
      !data?.campaign.isDraft
    ) {
      navigate('/')
      return
    }
  }, [data?.campaign, navigate])

  useEffect(() => {
    if (id) {
      getAsyncCampaign({
        variables: {
          id,
        },
      })
    }
  }, [id])
  const campaign = data?.campaign ?? null
  const showReferralQuestionnaire = !!campaign?.referralCampaign
  const hasReferralIncentives = useMemo(
    () =>
      campaign?.referralCampaign && !campaign?.reuseSameIncentives
        ? true
        : false,
    [campaign?.referralCampaign, campaign?.reuseSameIncentives],
  )
  const isPreview = pathname.includes('preview')
  return (
    <div className={cx(isPreview ? 'pt-0' : 'flex p-5 gap-24 pt-10')}>
      {!isPreview && (
        <ProgressBar
          completedStep={campaign?.completedStep || 0}
          showReferralQuestionnaire={showReferralQuestionnaire}
          hasReferralIncentives={hasReferralIncentives}
        />
      )}
      {loading && <Loading />}
      {(!id || (!loading && data)) && <Outlet context={{ campaign }} />}
    </div>
  )
}
