import { displayS3File } from '../../../../client/src/utils/s3'
import styles from './style.module.css'
import cx from 'classnames'
import { EndUserCampaign } from '../../../../server/src/graphql'
import i18n from '../../../../client/src/i18n'
import { endUserType } from '../../utils/user-type'
import { PartialEndUser } from '../../../../server/src/graphql'
import { TFunction } from 'i18next'

export default function Expired({
  campaign,
  endUser,
  theme,
  t,
}: {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: (path: string) => void
}) {
  const language = i18n.language
  const typeOfUser = endUserType(endUser.referral)
  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['expired']?.[language]

  return (
    <div
      className={cx(
        `theme-${theme} bg-white`,
        'grid justify-around h-screen bg-cover theme-theme2:bg-gradient-radial theme-theme1:bg-gradient-conic min-w-[339px] max-w-[375px] mx-auto',
      )}
    >
      <div
        className={cx(
          styles.contentWrapper,
          'flex flex-col items-center gap-8 text-center m-auto',
        )}
      >
        {campaign?.logo && (
          <img src={displayS3File(campaign.logo)} className="w-32" />
        )}
        <h1 className="text-2xl">
          {translations?.header ? translations?.header : t('ec.expired.title')}
        </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: translations?.text
              ? translations?.text
              : t('ec.expired.description'),
          }}
        />
      </div>
      <div className="flex flex-col justify-end items-center py-8">
        <span className="bg-ui1 rounded-full p-2 text-sm text-black-1 w-fit">
          <p className="px-2">
            powered by <strong>Alphabait</strong>
          </p>
        </span>
      </div>
    </div>
  )
}
