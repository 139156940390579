import { addDays, format } from 'date-fns'
import { ZodTypeAny, z } from 'zod'

const dateString = z.string().min(1, 'required')

const dateParsed = dateString
  .refine((value) => /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value), 'invalid_date')
  .transform((d: string) => new Date(d))

const DateType = {
  startDate: dateParsed,
  endDate: dateParsed,
}

const WithId = { id: z.string().uuid() }

const radioType = z.preprocess(
  (value) => (value ? value : ''),
  z.string().min(1, 'required'),
)

const BaseSchema = z.object({
  typeOfCampaign: radioType,
  name: z.string().min(1, 'required'),
  companyName: z.string().min(1, 'required'),
  startDate: dateString,
  endDate: dateString,
  details: z.string().min(1, 'required'),
  senderAddress: z.string().min(1, 'required'),
  referralCampaign: z.boolean(),
  emailAddress: z.string(),
  privacyLink: z.string().url({
    message: 'invalid_url',
  }),
  campaignCategory: z.enum(['generic', 'birthday', 'lottery'], {
    required_error: 'required',
    invalid_type_error: 'required',
  }),
  customerId: z.string().min(1, 'required'),
  ecFlowTexts: z.any(),
  scheduleDateTime: z
    .union([
      z.null(),
      z.undefined(),
      z.string().datetime({ message: 'Invalid datetime string! Must be UTC.' }),
    ])
    .optional(),
})

const withEffects = (schema: ZodTypeAny) =>
  schema
    .refine(
      (schema) => {
        if (schema.typeOfCampaign !== 'offline') return true
        return new Date(schema.startDate) > addDays(new Date(), 21)
      },
      {
        path: ['dates_error'],
        message: 'start_date_too_soon_offline',
      },
    )
    .refine(
      (schema) => {
        if (schema.typeOfCampaign !== 'online') return true
        return new Date(schema.startDate) > addDays(new Date(), 1)
      },
      {
        path: ['dates_error'],
        message: 'start_date_too_soon_online',
      },
    )
    // .refine(
    //   (schema) => {
    //     if (schema.typeOfCampaign !== 'online') return true
    //     return new Date(schema.scheduleDateTime) > addDays(new Date(), 1)
    //   },
    //   {
    //     path: ['schedule_datetime'],
    //     message: 'schedule_time_error',
    //   },
    // )
    .refine(
      (schema) =>
        new Date(schema.startDate) < addDays(new Date(schema.endDate), 1),
      {
        path: ['dates_error'],
        message: 'campaign_length_too_short',
      },
    )

const CreateCampaignSchema = withEffects(BaseSchema)
const CreateCampaignTypedSchema = withEffects(BaseSchema.extend(DateType))
const UpdateCampaignDetailsSchema = withEffects(
  BaseSchema.extend(WithId).required(),
)
const CampaignDetailsFormSchema = BaseSchema.extend({
  startDate: z
    .string()
    .transform((d: string) => (d ? format(new Date(d), 'yyyy-MM-dd') : d)),
  endDate: z
    .string()
    .transform((d: string) => (d ? format(new Date(d), 'yyyy-MM-dd') : d)),
}).required()

const UpdateCampaignDetailsTypedSchema = withEffects(
  BaseSchema.extend(WithId).extend(DateType).required(),
)

export type CreateCampaignType = z.infer<typeof CreateCampaignSchema>
export type UpdateCampaignDetailsType = z.infer<
  typeof UpdateCampaignDetailsSchema
>

export {
  CreateCampaignSchema,
  CreateCampaignTypedSchema,
  UpdateCampaignDetailsSchema,
  UpdateCampaignDetailsTypedSchema,
  CampaignDetailsFormSchema,
}
