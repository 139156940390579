import AlphaForm from '../../../../client/src/components/form'
import Header from '../../components/header'
import { BackButton, NextButton } from '../../components/buttons'
import styles from './style.module.css'
import cx from 'classnames'
import { PartialEndUser, EndUserCampaign } from '../../../../server/src/graphql'
import i18n from '../../../../client/src/i18n'
import { endUserType } from '../../utils/user-type'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'

export default function HappinessCheck({
  campaign,
  theme,
  endUser,
  t,
  pathname,
  navigate,
  onSubmit,
}: {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  pathname: string
  t: TFunction<'translation', undefined>
  id?: string
  navigate: NavigateFunction
  onSubmit: (data: Record<string, unknown>) => void
}) {
  const language = i18n.language
  const typeOfUser = endUserType(endUser.referral)

  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['happiness-check']?.[
      language
    ]

  if (!campaign?.happinessCheck) return <div>No data</div>

  return (
    <div
      className={cx(`theme-${theme}`, 'flex flex-col  theme-theme2:bg-white')}
    >
      <Header
        title={
          translations?.header ?? t('ec.success.title_with_happiness_check')
        }
        theme={theme}
        pathname={pathname}
      />

      <AlphaForm
        fields={[
          {
            className: cx(
              styles.contentWrapper,
              'flex flex-col justify-between h-full',
            ),
            children: [
              ...campaign.happinessCheck,
              {
                render: () => (
                  <div className="flex flex-col space-y-4 py-8 justify-between h-32 mb-4">
                    <NextButton type="submit" label={t('ec.finish')} t={t} />
                    <BackButton navigate={navigate} t={t} />
                  </div>
                ),
              },
            ],
          },
        ]}
        onSubmit={onSubmit}
        className={cx(styles.endUsersHappinessCheck, 'bg-white')}
        formClassName="w-full"
      />
    </div>
  )
}
