import { gql } from '@apollo/client'

export default gql`
  query GetIntersolveShops {
    getIntersolveShops {
      name
      id
      customerId
    }
  }
`
