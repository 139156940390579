import AlphaForm from '../../../../client/src/components/form'
import '../../../../client/src/components/form/form.module.css'
import { PartialEndUser, EndUserCampaign } from '../../../../server/src/graphql'
import Header from '../../components/header'
import { zodResolver } from '@hookform/resolvers/zod'
import { BackButton, NextButton } from '../../components/buttons'
import styles from './style.module.css'
import cx from 'classnames'

import {
  conditionalField,
  validateEmail,
  validateGender,
  validateHouseNumber,
  validatePhoneNumber,
  validatePostcode,
} from '../../utils/questionnaire-validations'
import { z } from 'zod'
import { AlphaTypes } from '../../../../client/src/components/form/custom-types'
import { FormProps, isLayout } from '@sourcelabbg/form/lib'
import i18n from '../../../../client/src/i18n'
import { endUserType } from '../../utils/user-type'
import { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router-dom'

export default function Questionnaire({
  campaign,
  theme,
  endUser,
  t,
  pathname,
  navigate,
  onSubmit,
}: {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: NavigateFunction
  onSubmit: (questionnaire: Record<string, unknown>) => void
}) {
  const language = i18n.language
  const typeOfUser = endUserType(endUser.referral)

  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['questionnaire']?.[
      language
    ]

  const form =
    endUser?.referralOf !== null && endUser.referralOf !== endUser.campaignId
      ? campaign?.referralQuestionnaire?.form
      : campaign?.questionnaire?.form

  const hasEmail = form.find(
    (field: AlphaTypes) =>
      isLayout(field) &&
      !isLayout(field.children?.[0]) &&
      // @ts-ignore
      field?.children?.[0].name === 'email',
  )
  const isEmailRequired =
    (endUser?.referralOf !== null &&
      endUser.referralOf !== endUser.campaignId) ||
    campaign.postcardTemplate?.['flyer']

  // Mark email as a required field when exist in the form for referral questionnaire and flayer campaign only
  const formWithRequiredEmail = form.map((field: AlphaTypes) => {
    if (isEmailRequired && hasEmail) {
      return isLayout(field) &&
        !isLayout(field.children?.[0]) &&
        // @ts-ignore
        field?.children?.[0].name === 'email'
        ? ({
            ...field,
            children: [
              {
                ...field.children?.[0],
                validations: ['required'],
              },
            ],
          } as AlphaTypes)
        : field
    }
    return field
  })

  // Add the Email field when does not exist in the form and the user does not have an email in the data for referral questionnaire and flayer campaign only
  const formWithEmail =
    !hasEmail && isEmailRequired
      ? [
          {
            children: [
              {
                name: 'email',
                type: 'input',
                uiOptions: {
                  label: 'E-mail',
                  inputType: 'text',
                },
                validations: ['required'],
              },
            ],
          },
          ...formWithRequiredEmail,
        ]
      : formWithRequiredEmail

  const QuestionnairePFSSchema = formWithEmail.reduce(
    (acc: Record<string, unknown>, q: AlphaTypes) => {
      const name =
        // @ts-ignore
        isLayout(q) && !isLayout(q.children?.[0]) && q.children?.[0].name
      const isRequired =
        (isLayout(q) &&
          !isLayout(q.children?.[0]) &&
          // @ts-ignore
          q.children?.[0].validations?.includes('required')) ??
        false
      if (name === 'postcode') {
        acc['postcode'] = validatePostcode(isRequired)
      } else if (name === 'phone') {
        acc['phone'] = validatePhoneNumber(isRequired)
      } else if (name === 'houseNumber') {
        acc['houseNumber'] = validateHouseNumber(isRequired)
      } else if (name === 'email') {
        acc['email'] = validateEmail(isRequired)
      } else if (name === 'gender') {
        acc['gender'] = validateGender(isRequired)
      } else if (name === 'recommendToFriend' && isRequired) {
        acc['recommendToFriend'] = conditionalField(isRequired)
      } else if (name) {
        acc[`${name}`] = conditionalField(isRequired)
      }

      return acc
    },
    {
      privacyPolicy: z.literal(true, {
        errorMap: () => {
          return { message: 'required' }
        },
      }),
      allowContactConsent: z.boolean(),
    },
  )

  if (!campaign) return <div>No data</div>

  return (
    <div className="flex flex-col justify-between overflow-scroll">
      <Header
        title={
          translations?.header
            ? translations?.header
            : t('ec.questionnaire.title')
        }
        theme={theme}
        pathname={pathname}
      />

      <div className={cx(`${theme}`, 'w-full bg-white')}>
        <div className={cx(styles.contentWrapper, 'm-auto')}>
          <AlphaForm
            className={styles.questionnaire}
            fields={[
              ...formWithEmail,
              {
                type: 'render',
                name: 'privacyPolicy',

                render: (field: any, formProps: FormProps) => {
                  return (
                    <div className="text-start items-center w-full m-auto text-base text-gray-2">
                      <p className="mt-1 text-sm">
                        {translations?.termsAndConditions
                          ? translations?.termsAndConditions
                          : t('ec.questionnaire.termsAndConditions.text1')}
                      </p>
                      <div className="w-full flex flex-row mt-2 rounded border border-ui1 px-3 py-4 text-sm">
                        <input
                          type="checkbox"
                          {...formProps.register(field.name)}
                          className="mr-5 border border-ui1 rounded"
                        />
                        <label>
                          {translations?.termsAndConditionsEndUser
                            ? translations?.termsAndConditionsEndUser
                            : t('ec.questionnaire.termsAndConditions.text2')}

                          <span className="text-marine-700 pl-1">*</span>
                        </label>
                      </div>
                    </div>
                  )
                },
              },
              {
                type: 'render',
                name: 'allowContactConsent',
                render: (field: any, formProps: FormProps) => {
                  return (
                    <div>
                      <div className="w-full flex flex-row rounded border border-ui1 px-3 py-4  text-sm">
                        <input
                          type="checkbox"
                          {...formProps.register(field.name)}
                          className="mr-5 border border-ui1 rounded"
                        />
                        <label>
                          <a href={campaign.privacyLink}>
                            {campaign.companyName}&nbsp;
                          </a>
                          {translations?.termsAndConditionsReferral
                            ? translations?.termsAndConditionsReferral
                            : t('ec.questionnaire.termsAndConditions.text3')}
                        </label>
                      </div>
                      <p className="text-start items-center text-xs pt-4">
                        {translations?.termsAndConditionsSummary
                          ? translations?.termsAndConditionsSummary
                          : t('ec.questionnaire.termsAndConditions.text4')}
                        <a href={campaign.privacyLink}>
                          &nbsp;{campaign.companyName}
                        </a>
                        .
                      </p>
                    </div>
                  )
                },
              },
              {
                render: () => (
                  <div className="grid gap-4 my-4">
                    <NextButton type="submit" t={t} />
                    <BackButton navigate={navigate} t={t} />
                  </div>
                ),
              },
            ]}
            onSubmit={onSubmit}
            resolver={zodResolver(z.object(QuestionnairePFSSchema))}
          />
        </div>
      </div>
    </div>
  )
}
