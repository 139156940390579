import { UpdateIncentiveItemInput } from '@/gql'
import { IncentiveForm } from './incentive-form'
import { useEffect, useRef } from 'react'

type IncentiveModalProps = {
  onCloseModal: () => void
  incentiveByModal?: UpdateIncentiveItemInput
}

export default function IncentiveModal({
  incentiveByModal,
  onCloseModal,
}: IncentiveModalProps) {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.dropdown-content')
      ) {
        onCloseModal()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="absolute h-screen" data-testid="create-incentive-item-form">
      <div className="fixed top-0 left-0 z-30 w-full h-screen bg-ui1 opacity-75"></div>
      <div className="flex justify-center fixed top-10 left-0 z-40 w-full h-full">
        <div
          className="flex flex-col w-3/6 absolute p-6 z-50 bg-white rounded-md shadow-md overflow-y-scroll h-5/6"
          ref={ref}
        >
          <IncentiveForm
            incentiveByModal={incentiveByModal}
            onCloseModal={onCloseModal}
          />
        </div>
      </div>
    </div>
  )
}
