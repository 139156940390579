import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

import { useMemo } from 'react'
import { GetCampaignFragment } from '@/graphql/campaign/fragments/get-campaign'

import { useTranslation } from 'react-i18next'
import { Button } from './button'
import { CaretRight } from '@phosphor-icons/react'
import { userType } from '../../../end-users/src/utils/user-type'
import { ECFlowScreensType, TypeOfUser } from '@/schema/ec-flow-texts.schema'
import { ArrowLeft } from 'lucide-react'
import { useQuery } from '@apollo/client'
import GET_CAMPAIGN from '@/graphql/campaign/queries/get'
import { Campaign } from '@/gql'

export default function EndUserPreviewLayout() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const typeOfUser = userType(pathname)
  const isReferralUser = typeOfUser === TypeOfUser.REFERRAL_USER

  const { loading } = useQuery<{
    campaign: Campaign
  }>(GET_CAMPAIGN, {
    variables: { id },
  })

  // id && assertGraphQlRequest(data, error, loading)
  const { campaign } = useOutletContext<{ campaign: GetCampaignFragment }>()

  const currentScreenName = useMemo(
    () => pathname.split('/').pop() ?? ECFlowScreensType.HOME,
    [pathname],
  )

  const theme = useMemo(
    () => campaign?.questionnaire?.theme?.key,
    [campaign?.questionnaire?.theme?.key],
  )

  if (loading) return <p>Loading...</p>

  const referralIncentives = campaign?.campaignIncentives?.filter(
    (incentive) => incentive?.referral,
  )

  const incentives = campaign?.campaignIncentives?.filter(
    (incentive) => !incentive?.referral,
  )
  const nextScreenIndex =
    Object.values(ECFlowScreensType).findIndex(
      (value) => value === currentScreenName,
    ) + 1
  const nextScreenName = Object.values(ECFlowScreensType)[nextScreenIndex]
  const endUser = {
    id: 'b15342f0-a5d6-4187-a5b1-e73cba228f66',
    firstName: 'Vincent',
    middle: 'van',
    lastName: 'Gogh',
    email: 'vincent@example.com',
    address: {
      city: 'Amsterdam',
      country: 'The Netherlands',
      postcode: '1234AB',
      streetName: 'Windmolenstraat 10',
      houseNumber: '4a',
    },
    birthday: '1993-06-27',
    campaignId: 'b296428f-e65b-4cdb-a882-ffd35a64a995',
    campaignIncentiveId: null,
    phoneNumber: '1234567890',
    sku: 'AA00A00',
    digitalCode: 'code',
    uniqueCode: 'AKX1TB',
    referralOf: isReferralUser ? '2dbb52a2-6097-4bcb-83b8-c11af07a89d8' : null,
  }

  if (!id) return <p>Missing id</p>

  return (
    <div className="grid w-full h-fit pb-4">
      <a
        href={`/campaigns/ec-flow/${id}/end-user/home`}
        className="flex items-center gap-2 py-2"
      >
        <ArrowLeft className="cursor-pointer" />
        Go Back
      </a>
      <div className="overflow-y-scroll">
        <div className="pointer-events-none">
          <Outlet
            context={{
              campaign: {
                ...campaign,
                campaignIncentives: isReferralUser
                  ? referralIncentives
                  : incentives,
              },
              endUser,
              theme,
            }}
          />
        </div>
      </div>

      {nextScreenName && (
        <div className="flex justify-end mt-8 pr-4">
          <Button
            onClick={() => {
              let nextStep = nextScreenName
              if (
                campaign.happinessCheck === null &&
                nextScreenName === ECFlowScreensType.HAPPINESS_CHECK
              ) {
                nextStep = ECFlowScreensType.SUCCESS
              }
              navigate(
                `/campaigns/ec-flow/${id}/preview/${typeOfUser}/${nextStep}`,
              )
            }}
            type="submit"
            name="next"
            IconRight={CaretRight}
            label={t('Next screen')}
            data-testid="submit"
          />
        </div>
      )}
    </div>
  )
}
