import { CaretRight, FileDashed } from '@phosphor-icons/react'
import { Button } from './button'
import { useTranslation } from 'react-i18next'

type Props = {
  onSaveDraft?: () => void
  onSubmit?: () => void
  submitLabel?: string
  disableSaveDraft?: boolean
  saveDraftLabel?: string
  disableSubmit?: boolean
}

export default function CampaignButtons({
  onSaveDraft,
  onSubmit,
  submitLabel,
  saveDraftLabel,
  disableSaveDraft = true,
  disableSubmit = false,
}: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex justify-end my-10">
      <div className="grid grid-cols-2 gap-4 justify-end py-8">
        <Button
          IconRight={FileDashed}
          onClick={onSaveDraft}
          type="submit"
          name="save"
          label={saveDraftLabel ?? t('save_draft')}
          data-testid="save-draft"
          disabled={!disableSaveDraft}
          className="disabled:pointer-events-none disabled:opacity-50"
        />
        <Button
          primary={true}
          onClick={onSubmit}
          type="submit"
          name="next"
          IconRight={CaretRight}
          label={submitLabel || t('next_step')}
          data-testid="submit"
          disabled={disableSubmit}
        />
      </div>
    </div>
  )
}
