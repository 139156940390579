import cx from 'classnames'

type HeaderProps = {
  pathname: string
  title?: string
  subTitle?: string
  theme?: string
}

export default function Header({
  title,
  subTitle,
  theme,
  pathname,
}: HeaderProps) {
  const isEcFlowMobilePreview = pathname.includes('ec-flow')

  return (
    <div
      className={cx(
        pathname.includes('campaigns')
          ? 'w-auto bg-cover'
          : 'h-screen w-screen bg-cover',
        `${theme}  min-h-[200px]`,
        {
          'bg-theme2': theme === 'theme2',
          'pt-4': isEcFlowMobilePreview,
        },
      )}
    >
      <div className="text-theme-header-text mobile:w-[350px] fold:w-[250] m-auto text-center space-y-4 py-6">
        <h1 className="mobile:text-3xl fold:text-2xl font-bold max-w-sm">
          {title}
        </h1>
        <p
          className="mobile:text-xl fold:text-lg"
          dangerouslySetInnerHTML={{ __html: subTitle ? subTitle : '' }}
        />
      </div>
    </div>
  )
}
