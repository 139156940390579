import { PartialEndUser, EndUserCampaign } from '../../../../server/src/graphql'
import { displayS3File } from '../../../../client/src/utils/s3'
import styles from './style.module.css'
import cx from 'classnames'
import i18n from '../../../../client/src/i18n'
import { endUserType } from '../../utils/user-type'
import { TFunction } from 'i18next'

export default function Success({
  campaign,
  endUser,
  theme,
  t,
}: {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: (path: string) => void
}) {
  const language = i18n.language
  const typeOfUser = endUserType(endUser.referral)

  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['success']?.[language]

  const { logo } = campaign

  const isFlyer = campaign.postcardTemplate?.['type'] === 'flyer'

  return (
    <div
      className={cx(
        `theme-${theme} bg-white`,
        'grid justify-around h-screen bg-cover min-w-[339px] max-w-[375px] mx-auto',
      )}
    >
      <div
        className={cx(
          styles.contentWrapper,
          'flex flex-col items-center gap-8 text-center m-auto',
        )}
      >
        {logo && <img src={displayS3File(logo)} className="w-32" />}

        <h1 className="text-2xl">
          {translations?.header
            ? translations?.header
            : isFlyer
            ? t('ec.success.flyer_title')
            : t('ec.success.title')}
        </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: translations?.text
              ? translations?.text
              : t('ec.success.sub_title'),
          }}
        />
      </div>
    </div>
  )
}
