import { Outlet, useLocation } from 'react-router-dom'
import Navigation from './navigation'
import { Suspense } from 'react'

export default function Layout() {
  const location = useLocation()
  const { pathname } = location
  return (
    <div className="h-screen flex flex-col">
      {/* // This is the Navigation component */}
      {!pathname.includes('preview') && <Navigation />}
      <div className="h-fit flex-1">
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}
