import { gql } from '@apollo/client'
import GetCampaignFragment from '../fragments/get-campaign'

export default gql`
  ${GetCampaignFragment}
  mutation CreateECFlowTexts($ecFlowTexts: CreateECFlowTextsInput) {
    createECFlowTexts(ecFlowTexts: $ecFlowTexts) {
      ...GetCampaignFragment
    }
  }
`
