import { addMinutes, setMilliseconds, setSeconds } from 'date-fns'

export function generateCode(numberOfChars: number): string {
  const list = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789'
  const randomChar = () =>
    list.at(Math.floor(Math.random() * list.length)) ?? ''
  if (numberOfChars == 1) return randomChar()

  return randomChar() + generateCode(numberOfChars - 1)
}

/**
 * This function is making sure that we don't send every event to the SQS queue but instead we wait for at least 3 minutes.
 * When end user scans the code it is highly possible that they will either go through all the steps in the next few minutes or they
 * will stay at the first step for a while.
 * We set the time to finish to 3 minutes and we round to the nearest 5 minutes that are at least 3 minutes ahead of time.
 *
 * Examples:
 * If the user starts filling in the form at 11:00 the event will be sent at 11:05 (3 minutes tolerance and round to the next 5)
 * If the user starts filling in the form at 11:03 the event will be sent at 11:10 (3 minutes tolerance and round to the next 5)
 * If the user starts filling in the form at 11:02 the event will be sent at 11:10 (3 minutes tolerance and round to the next 5)
 * */
export function roundToNext5Min(date: Date = new Date()) {
  const minutes = date.getMinutes()
  const diff = 5 - (minutes % 5) // how many minutes left to the nearest 5
  const tolerance = diff < 4 ? 5 : 0 // the given time for user to finish the steps

  return setSeconds(setMilliseconds(addMinutes(date, diff + tolerance), 0), 0)
}

export const getEndUserPreviewData = (isReferralUserPreview: boolean) => {
  return {
    id: 'b15342f0-a5d6-4187-a5b1-e73cba228f66',
    firstName: isReferralUserPreview ? '' : 'Vincent',
    middle: 'van',
    lastName: 'Gogh',
    email: 'vincent@example.com',
    address: {
      city: 'Amsterdam',
      country: 'The Netherlands',
      postcode: '1234AB',
      streetName: 'Windmolenstraat 10',
      houseNumber: '4a',
    },
    birthday: '1993-06-27',
    campaignIncentiveId: null,
    phoneNumber: '1234567890',
    sku: 'AA00A00',
    digitalCode: 'code',
    uniqueCode: isReferralUserPreview ? 'referral-user' : 'end-user',
    referralOf: isReferralUserPreview
      ? 'b15342f0-a5d6-4187-a5b1-e73cba228f66'
      : null,
  }
}

export function obfuscateString(str: string, last: number = 4) {
  return `${str.slice(0, last * -1)}${str.slice(last * -1).replace(/./g, '*')}`
}

export const getNameFormat = (
  nameFormat: string,
  endUser: { firstName: string; middle?: string | null; lastName: string },
) => {
  switch (nameFormat) {
    case 'firstName':
      return endUser.firstName
    case 'lastName':
      return endUser.middle
        ? `${endUser.middle} ${endUser.lastName}`
        : endUser.lastName
    case 'fullName':
      return endUser.middle
        ? `${endUser.firstName} ${endUser.middle} ${endUser.lastName}`
        : `${endUser.firstName} ${endUser.lastName}`
    default:
      return ''
  }
}
