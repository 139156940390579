import { useCallback, useEffect, useState } from 'react'
import { Button } from '../button'
import AlphaForm from '../form'
import {
  AlphaTypes,
  RadioGroupWithDescriptionInput,
} from '../form/custom-types'
import USER_QUERY from '@/graphql/user'
import INCENTIVE_CATEGORY_QUERY from '@/graphql/incentive/queries/categories'
import CREATE_INCENTIVE_MUTATION from '@/graphql/incentive/mutations/create'
import UPDATE_INCENTIVE_MUTATION from '@/graphql/incentive/mutations/update'
import INCENTIVE_BY_ID_QUERY from '@/graphql/incentive/queries/incentive'
import INCENTIVE_BY_CATEGORY_QUERY from '@/graphql/incentive/queries/incentive-incentive-category'
import DELETE_INCENTIVE_MUTATION from '@/graphql/campaign/mutations/delete-incentive'
import INCENTIVES_STORE_QUERY from '@/graphql/incentive/queries/incentives-store'
import CUSTOMERS_QUERY from '@/graphql/customer'
import {
  Customer,
  Incentive,
  IncentiveCategory,
  UpdateIncentiveItemInput,
} from '@/gql'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useMatch, useOutletContext, useParams } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  createIncentiveItemFormSchema,
  updateIncentiveItemFormSchema,
} from '@/schema/incentive.schema'
import { createPortal } from 'react-dom'
import ErrorModal from '../error-modal'
import {
  DownloadSimple,
  Trash,
  Download,
  FileCsv,
  ArrowLeft,
} from '@phosphor-icons/react'
import { GetCampaignFragment } from '@/graphql/campaign/fragments/get-campaign'
import cx from 'classnames'
import IntersolveSettings from './custom-properties/intersolve'
import { LayoutProps } from '@sourcelabbg/form/lib'

type IncentiveCategoriesResponse = {
  incentiveCategories: IncentiveCategory[]
}
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
export const IncentiveForm = ({
  onCloseModal,
  incentiveByModal,
}: {
  incentiveByModal?: UpdateIncentiveItemInput
  onCloseModal?: () => void
}) => {
  const { t } = useTranslation()
  const { id, incentiveId: urlIncentiveId } = useParams()
  const [incentiveId, setIncentiveId] = useState<string | undefined>(
    urlIncentiveId,
  )
  useEffect(() => {
    if (!incentiveId) {
      setIncentiveId(urlIncentiveId || uuidv4())
    }
  }, [incentiveId, urlIncentiveId])

  const [values, setValues] = useState<Partial<Incentive | undefined>>()
  const [selectedType, setSelectedType] = useState<string | undefined>()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<{
    title: string
    text: string
  }>()
  const { data: user, loading: loadingUser } = useQuery(USER_QUERY, {})

  const { campaign } =
    useOutletContext<{ campaign: GetCampaignFragment }>() ?? {}

  const [getIncentiveById, { loading: loadingIncentive, data: incentive }] =
    useLazyQuery(INCENTIVE_BY_ID_QUERY, {
      variables: { id: incentiveId ?? '' },
      fetchPolicy: 'network-only',
    })

  const incentiveById = incentive?.incentiveById ?? incentiveByModal

  const isEditModal = useMatch('/campaigns/incentives/:id')?.pathname.includes(
    '/campaigns/incentives/',
  )

  const navigateTo = () => {
    if (isEditModal && onCloseModal) {
      onCloseModal()
    } else {
      window.location.href = '/incentives'
    }
  }

  const [createMutation, { error }] = useMutation(CREATE_INCENTIVE_MUTATION, {
    refetchQueries: () => [
      {
        query: INCENTIVE_BY_CATEGORY_QUERY,
        variables: {
          customerId: campaign?.customerId,
        },
      },
      { query: INCENTIVES_STORE_QUERY, variables: {} },
    ],
  })

  const [updateMutation, { error: updateIncentiveError }] = useMutation(
    UPDATE_INCENTIVE_MUTATION,
    {
      refetchQueries: () => [
        {
          query: INCENTIVE_BY_CATEGORY_QUERY,
          variables: {
            customerId:
              (campaign?.customerId || incentiveById?.customerId) ?? undefined,
          },
        },
        { query: INCENTIVES_STORE_QUERY, variables: {} },
      ],
    },
  )

  const { data: categoriesData, loading: categoriesLoading } =
    useQuery<IncentiveCategoriesResponse>(INCENTIVE_CATEGORY_QUERY, {})

  const { data: customersData, loading: customersLoading } = useQuery(
    CUSTOMERS_QUERY,
    {},
  )

  const [deleteIncentive] = useMutation(DELETE_INCENTIVE_MUTATION, {
    refetchQueries: () => [
      {
        query: INCENTIVE_BY_CATEGORY_QUERY,
        variables: {
          customerId:
            (campaign?.customerId || incentiveById?.customerId) ?? undefined,
        },
      },
      { query: INCENTIVES_STORE_QUERY, variables: {} },
    ],
    onCompleted() {
      navigateTo()
    },
  })

  const onClickDelete = () => {
    deleteIncentive({
      variables: { incentiveId: incentiveById?.id },
    }).catch((error) => {
      const parsedUpdateIncentiveError = JSON.parse(error.message) as {
        message: string
        numberOfCampaigns: string
      }
      setErrorMessage({
        title: t('incentive_errors_modal.delete_error_modal_title', {
          numberOfCampaigns: parsedUpdateIncentiveError?.numberOfCampaigns,
        }),
        text: t(
          `incentive_errors_modal.${parsedUpdateIncentiveError?.message}`,
          {
            numberOfCampaigns: parsedUpdateIncentiveError?.numberOfCampaigns,
          },
        ),
      })
      setShowErrorModal(true)
    })
  }

  useEffect(() => {
    setSelectedType(incentiveById?.type)
    setValues({
      ...incentiveById,
      price: incentiveById?.price.toString(),
    })
  }, [incentiveById?.id])

  useEffect(() => {
    urlIncentiveId && getIncentiveById({ variables: { urlIncentiveId } })
  }, [urlIncentiveId])

  const onChange = (field: string, change: { old: string; new: string }) => {
    if (field === 'type') {
      setSelectedType(change.new)
      setValues((prevValues) => ({
        ...prevValues,
        type: change.new,
      }))
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: change.new,
      }))
    }
  }

  const onSubmit = useCallback(
    async (data: Partial<UpdateIncentiveItemInput>) => {
      setValues({ ...data })

      if (incentiveById?.id) {
        updateMutation({
          variables: {
            id: id ?? '',
            incentiveItem: {
              ...data,
              id: incentiveById.id,
              price: Number(data.price),
              integrationId: data.integrationId || null,
              customerId:
                user?.user?.role === 'Superadmin'
                  ? data.customerId ?? null
                  : user?.user?.customerId,
            },
          },
          onCompleted(_data) {
            setValues(undefined)
            navigateTo()
          },
          onError(error) {
            const parsedUpdateIncentiveError = JSON.parse(error.message) as {
              message: string
              numberOfCampaigns: string
            }
            setErrorMessage({
              title: t('incentive_errors_modal.update_error_modal_title', {
                numberOfCampaigns:
                  parsedUpdateIncentiveError?.numberOfCampaigns,
              }),
              text: t(
                `incentive_errors_modal.${parsedUpdateIncentiveError?.message}`,
                {
                  numberOfCampaigns:
                    parsedUpdateIncentiveError?.numberOfCampaigns,
                },
              ),
            })
            setShowErrorModal(true)
          },
        })
      } else {
        createMutation({
          variables: {
            id: id ?? '',
            incentiveItem: {
              ...data,
              price: Number(data.price),
              integrationId: data.integrationId || null,
              customerId:
                user?.user?.role === 'Superadmin'
                  ? data.customerId ?? null
                  : user?.user.customerId,
            },
          },
          onCompleted(_data) {
            setValues(undefined)
            navigateTo()
          },
        })
      }
    },
    [
      incentiveById?.id,
      campaign?.customerId,
      id,
      createMutation,
      updateMutation,
    ],
  )
  if (loadingIncentive || categoriesLoading || loadingUser || customersLoading)
    return <div>{t('loading')}</div>

  const isIntersolveType = values?.type === 'intersolve'

  const createIncentiveFields: AlphaTypes[] = [
    {
      render: () => (
        <>
          {isEditModal ? (
            <>
              <h1 className="text-lg text-gray-9 font-bold">
                {t('add_incentive.add_incentive')}
              </h1>
              <h2 className="text-sm text-gray-10">
                {t('add_incentive.upload_here')}
              </h2>
            </>
          ) : (
            <div className="flex gap-x-2 align-middle mb-8 items-center">
              <button onClick={() => navigateTo()}>
                <ArrowLeft
                  width={44}
                  height={24}
                  className="items-start flex -ml-2"
                />
              </button>
              {incentiveById?.id ? (
                <div className="grid">
                  <div className="flex gap-x-2 items-center">
                    <h1 className="mb-0">{incentiveById.name}</h1>
                    <div
                      className={cx(
                        incentiveById.isActive ? 'bg-primary' : 'bg-ui1',
                        'w-2 h-2 rounded-full ',
                      )}
                    ></div>
                  </div>
                  <p className="text-gray-2 text-xs">
                    {incentiveById?.sku ? `SKU: ${incentiveById.sku}` : ''}
                  </p>
                </div>
              ) : (
                <h1 className="m-0">{t('incentives_store.add_incentive')}</h1>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: 'picture',
      type: 'custom',
      customControlType: 'fileUpload',
      uploadUrl: `/upload/incentive-item?incentiveItemId=${incentiveId}`,
      allowedFileTypes: ['jpg', 'png'],
      uiOptions: {
        disabled: incentiveById?.isClaimed ?? false,
      },
    },

    {
      children: [
        {
          name: 'name',
          type: 'input',
          uiOptions: {
            label: t('add_incentive.name'),
            inputType: 'text',
            testId: 'incentive-name',
            className: 'pr-2',
            disabled: incentiveById?.isClaimed ?? false,
          },
        },
        {
          name: 'price',
          type: 'input',
          uiOptions: {
            label: t('add_incentive.price'),
            inputType: 'number',
            testId: 'incentive-price',
            disabled: incentiveById?.isClaimed ?? false,
          },
        },
      ],
    },
    {
      name: 'customerId',
      type: 'select',
      options:
        customersData?.customers?.map((customer: Customer) => ({
          value: customer.id,
          label: customer.name,
          data: customer,
        })) || [],
      uiOptions: {
        label: t('add_incentive.customer'),
        testId: 'incentive-customer-select',
        visible: () => user?.user?.role === 'Superadmin',
        disabled: incentiveById?.isClaimed ?? false,
      },
    },
    {
      name: 'description',
      type: 'custom',
      customControlType: 'textarea',
      uiOptions: {
        label: t('add_incentive.description'),
        testId: 'incentive-description',
        disabled: incentiveById?.isClaimed ?? false,
      },
    },
    {
      name: 'categoryId',
      type: 'select',
      options:
        categoriesData?.incentiveCategories?.map((category) => ({
          value: category.id,
          label: t(`incentive_categories.${category.name}`),
          data: category,
        })) || [],
      uiOptions: {
        label: t('add_incentive.category'),
        testId: 'incentive-category',
        disabled: incentiveById?.isClaimed ?? false,
      },
    },
    {
      name: 'type',
      type: 'custom',
      customControlType: 'radioGroupWithDescription',
      uiOptions: {
        testId: 'campaign-category',
        className: 'mb-4',
        label: t('add_incentive.type'),
        disabled: incentiveById?.isClaimed ?? false,
      },
      options: [
        {
          name: 'unique',
          label: t('incentives.types.unique_digital'),
        },
        {
          name: 'universal',
          label: t('incentives.types.universal_digital'),
        },
        {
          name: 'physical',
          label: t('incentives.types.physical'),
        },
        {
          name: 'intersolve',
          label: t('incentives.types.intersolve'),
        },
      ],
    } as RadioGroupWithDescriptionInput,
    {
      name: 'skuCode',
      type: 'input',
      uiOptions: {
        label: t('incentives.sku_code'),
        inputType: 'text',
        testId: 'incentive-sku-code',
        disabled:
          ((incentiveById && !!incentiveById?.isAttachedToLotteryCampaign) ||
            incentiveById?.isClaimed) ??
          false,
      },
    },
    {
      name: 'digitalCode',
      type: 'input',
      uiOptions: {
        label: t('incentives.digital_code'),
        inputType: 'text',
        testId: 'incentive-digital-code',
        disabled:
          (incentiveById && !!incentiveById?.isAttachedToLotteryCampaign) ??
          false,
        visible: () => selectedType === 'universal',
      },
    },
    {
      render: (layoutProps: LayoutProps) => {
        if (selectedType === 'unique')
          return (
            <div className="flex flex-row cursor-pointer mt-6 justify-between items-center">
              <div>{t('add_incentive.upload_voucher_code_file')}</div>
              <div className="flex">
                <Download className="self-center mr-2" />
                <a
                  className="text-primary"
                  href="/download/example-voucher-codes.csv"
                  download={true}
                >
                  {t('add_incentive.download_example')}
                </a>
              </div>
            </div>
          )
        if (selectedType === 'intersolve')
          return (
            <div className="my-2 space-y-1">
              <div className="">{t('add_incentive.brand_type')}</div>
              <IntersolveSettings
                // value={values?.integrationId ? values.integrationId : undefined}
                onChange={(value) =>
                  layoutProps.setValue('integrationId', value)
                }
                customerId={values?.customerId}
                disabled={incentiveById?.isClaimed}
              />
            </div>
          )
      },
    },
    {
      name: 'integrationId',
      type: 'input',
      uiOptions: {
        inputType: 'text',
        className: 'hidden',
        disabled: incentiveById?.isClaimed ?? false,
      },
    },
    {
      name: 'voucherCodesFilePath',
      type: 'custom',
      customControlType: 'fileUpload',
      uiOptions: {
        visible: () => selectedType === 'unique',
      },
      uploadUrl: incentiveId
        ? `/upload/incentive-item-codes?incentiveItemId=${incentiveId}`
        : '/upload/incentive-item-codes',
      allowedFileTypes: ['csv', 'xls', 'xlsx'],
    },
    {
      render: () => (
        <>
          {/* TODO: should delete voucher codes file should be deleted if teh type is not unique */}
          {values?.files && incentiveById.type === 'unique' && (
            <h1 className="my-4">{t('incentives_store.uploaded_files')}</h1>
          )}
          {values?.files
            ?.filter((file) => file?.fileType === 'VOUCHER_CODES')
            ?.filter((file) => !!file)
            ?.map((file, index) => {
              if (!file) return <></>
              return (
                <div
                  key={index}
                  className="flex flex-row gap-2 p-4 border border-gray-5 rounded-md items-center justify-between my-2"
                >
                  <div className="flex gap-4">
                    <FileCsv size={26} className="self-center" />
                    {file.s3Key}
                  </div>
                  <div className="flex gap-4">
                    <a href={file.url?.toString()}>
                      <DownloadSimple size={24} />
                    </a>
                  </div>
                </div>
              )
            })}
        </>
      ),
    },
    {
      className: 'grid my-4',
      children: [
        {
          render: () => <p className="pb-4">{t('incentives_store.status')}</p>,
        },
        {
          className: 'flex w-full bg-gray-8 rounded-md p-4 items-center',
          children: [
            {
              name: 'isActive',
              type: 'custom',
              customControlType: 'switch',
              uiOptions: {
                className: 'switch flex flex-col ',
                //TODO: fix the custom switch component to use the labelPosition prop
                // labelPosition: 'right',
              },
            },
            {
              render: () => (
                <div className="px-4">{t('incentives_store.active')}</div>
              ),
            },
          ],
        },
      ],
    },

    {
      render: () => (
        <>
          {(error || updateIncentiveError) && values && (
            <p className="text-primary">
              {t(
                error
                  ? `${error?.message}`
                  : `${updateIncentiveError?.message}`,
                {
                  sku: values?.skuCode,
                },
              )}
            </p>
          )}
          <div
            className={cx(
              incentiveById?.id ? 'flex justify-between' : 'flex justify-end',
              'py-8',
            )}
          >
            {incentiveById?.id && (
              <Button
                type="button"
                name="delete"
                label={t('delete')}
                size="small"
                IconLeft={Trash}
                onClick={() => {
                  const featured = incentiveById?.incentiveAttachedToCampaign
                  return featured
                    ? (setShowErrorModal(true),
                      setErrorMessage({
                        title: t(
                          'incentive_errors_modal.delete_error_modal_title',
                          {
                            numberOfCampaigns: featured,
                          },
                        ),
                        text: t(
                          'incentive_errors_modal.delete_incentive_item_is_used',
                          {
                            numberOfCampaigns: featured,
                          },
                        ),
                      }))
                    : (setShowErrorModal(true),
                      setErrorMessage({
                        title: t(
                          ' are you sure you want to delete this incentive?',
                        ),
                        text: t(
                          ' are you sure you want to delete this incentive?',
                        ),
                      }))
                }}
                disabled={
                  user?.user?.role === 'Superadmin'
                    ? false
                    : !(user?.user?.customerId === incentive?.customerId) ||
                      incentiveById?.isClaimed
                }
                data-testid="incentive-delete"
              />
            )}
            <div className="grid grid-cols-2 gap-4 justify-end items-end">
              <Button
                type="button"
                name="cancel"
                label={t('cancel')}
                size="small"
                onClick={() => {
                  setValues(undefined)
                  navigateTo()
                }}
                data-testid="incentive-cancel"
              />
              <Button
                primary={true}
                type="submit"
                name="add-incentive"
                label={
                  incentiveById?.id ? t('save') : t('incentives_store.add')
                }
                size="small"
                data-testid="incentive-submit"
                disabled={
                  user?.user?.role === 'Superadmin'
                    ? false
                    : user?.user?.customerId === incentiveById?.customerId
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </>
      ),
    },
  ]

  return (
    <div className="p-8 space-y-2 flex flex-col max-w-2xl m-auto">
      <AlphaForm
        fields={createIncentiveFields}
        onSubmit={onSubmit}
        resolver={zodResolver(
          incentive?.id
            ? updateIncentiveItemFormSchema(
                incentiveById?.isAttachedToLotteryCampaign,
                isIntersolveType,
              )
            : createIncentiveItemFormSchema(
                incentiveById?.isAttachedToLotteryCampaign,
                isIntersolveType,
              ),
        )}
        values={{
          ...values,
          id: incentiveId,
          name: values?.name,
          categoryId: values?.categoryId ?? undefined,
          skuCode: values?.skuCode,
          integrationId: values?.integrationId,
          price: values?.price,
          picture: values?.picture ?? undefined,
          customerId: values?.customerId || campaign?.customerId || null,
          digitalCode: values?.digitalCode,
          type: selectedType || values?.type,
        }}
        onFieldChange={onChange}
      />

      {showErrorModal &&
        createPortal(
          <ErrorModal
            title={errorMessage?.title ?? ''}
            error={errorMessage?.text ?? ''}
            onClick={() => setShowErrorModal(false)}
            hasOnSubmit={!incentiveById?.isClaimed}
            onSubmit={() => onClickDelete()}
            submitButtonText={t('delete')}
          />,
          document.body,
        )}
    </div>
  )
}
