import { z } from 'zod'

export const BaseSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, 'required'),
  picture: z.string().min(1, 'required'),
  price: z.number(),
  description: z.string().min(1, 'required').max(200),
  categoryId: z
    .string({ required_error: 'required', invalid_type_error: 'required' })
    .min(1, 'required'),
  type: z.enum(['unique', 'universal', 'physical', 'intersolve'], {
    required_error: 'required',
    invalid_type_error: 'required',
  }),
  skuCode: z.string().optional().nullable(),
  digitalCode: z.string().optional().nullable(),
  integrationId: z.string().optional().nullable(),
  voucherCodesFilePath: z.string().optional().nullable(),
  customerId: z.string().optional().nullable(),
  isActive: z.boolean(),
})

export const UpdateSchema = BaseSchema.extend({
  id: z.string().min(1, 'required'),
})

export const createIncentiveItemFormSchema = (
  isLotteryCampaign?: boolean,
  isIntersolveType?: boolean,
) =>
  BaseSchema.extend({
    skuCode: isLotteryCampaign
      ? z.string().min(1, 'required')
      : z.string().optional().nullable(),
    price: z
      .string()
      .min(1, 'required')
      .regex(/^[\d]+\.{0,1}[\d]{0,2}$/, 'invalid_number'),
    integrationId: isIntersolveType
      ? z
          .string({
            required_error: 'required',
            invalid_type_error: 'required',
          })
          .min(1, { message: 'required' })
      : z.string().optional().nullable(),
  })
export const updateIncentiveItemFormSchema = (
  isLotteryCampaign?: boolean,
  isIntersolveType?: boolean,
) =>
  BaseSchema.extend({
    id: z.string().min(1, 'required'),
    skuCode: isLotteryCampaign
      ? z.string().min(1, 'required')
      : z.string().optional().nullable(),
    price: z.any().optional().nullable(),
    categoryId: z.any().optional().nullable(),
    integrationId: isIntersolveType
      ? z
          .string({
            required_error: 'required',
            invalid_type_error: 'required',
          })
          .min(1, { message: 'required' })
      : z.string().optional().nullable(),
  })
export type CreateIncentiveItemType = z.infer<typeof BaseSchema>
