import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activeStepState } from '../../store/atoms/active-step'
import { useEffect, useMemo, useState } from 'react'
import CREATE_EC_FLOW_TEXTS from '../../graphql/campaign/mutations/create-ec-flow-texts'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { AlphaTypes } from '../../components/form/custom-types'
import AlphaForm from '../../components/form'
import { GetCampaignFragment } from '../../graphql/campaign/fragments/get-campaign'
import CampaignButtons from '../../components/campaign-buttons'
import { SubmitHandler } from 'react-hook-form'
import {
  ECFlowTextsFormType,
  ECFlowScreensType,
  TypeOfUser,
} from '@/schema/ec-flow-texts.schema'
import cx from 'classnames'
import { userType } from '../../../../end-users/src/utils/user-type'

export default function EndCustomerFlow(props: {
  campaign: GetCampaignFragment
}) {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const typeOfEndUser = userType(pathname)

  const setActiveStep = useSetRecoilState(activeStepState)
  const [createECFlowTexts] = useMutation(CREATE_EC_FLOW_TEXTS, {})

  const campaign: ECFlowTextsFormType = useMemo(() => {
    return props.campaign.ecFlowTexts || {}
  }, [props.campaign])

  const [typeOfUser, setTypeOfUser] = useState<TypeOfUser>(typeOfEndUser)
  const currentScreenName = useMemo(
    () => pathname.split('/').pop() ?? ECFlowScreensType.HOME,
    [pathname],
  )

  const values = useMemo(
    () =>
      (campaign.ecFlowTexts as any)?.[typeOfUser ?? '']?.[currentScreenName]
        ?.nl,
    [currentScreenName, typeOfUser, campaign.ecFlowTexts],
  )

  const ecFlowForm: AlphaTypes[] = useMemo(
    () => [
      {
        render: () => (
          <>
            {props.campaign.referralCampaign ? (
              <div className="flex w-3/4 gap-x-4 text-sm">
                <label
                  onClick={() => {
                    navigate(
                      `/campaigns/ec-flow/${id}/${TypeOfUser.END_USER}/home`,
                    )
                    setTypeOfUser(TypeOfUser.END_USER)
                  }}
                  className={cx(
                    'flex flex-1 cursor-pointer rounded-lg border-2 bg-white p-2 shadow-sm focus:outline-none hover:border-secondary',
                    `${typeOfUser === TypeOfUser.END_USER && 'border-black-1'}`,
                  )}
                >
                  <input
                    name={TypeOfUser.END_USER}
                    type="radio"
                    value={TypeOfUser.END_USER}
                    checked={typeOfUser === TypeOfUser.END_USER}
                    className="sr-only"
                    disabled={id ? true : false}
                  />
                  <span className="flex flex-col text-md">
                    <span className="font-bold">
                      {t('ec_flow_texts.end_user')}
                    </span>
                    <span className="font-medium text-gray-3">
                      {t('ec_flow_texts.original_receiver')}
                    </span>
                  </span>
                </label>
                <label
                  onClick={() => {
                    navigate(
                      `/campaigns/ec-flow/${id}/${TypeOfUser.REFERRAL_USER}/home`,
                    )
                    setTypeOfUser(TypeOfUser.REFERRAL_USER)
                  }}
                  className={cx(
                    'flex flex-1 cursor-pointer rounded-lg border-2 bg-white p-2 shadow-sm focus:outline-none hover:border-secondary',
                    `${
                      typeOfUser === TypeOfUser.REFERRAL_USER &&
                      'border-black-1'
                    }`,
                  )}
                >
                  <input
                    name={TypeOfUser.REFERRAL_USER}
                    type="radio"
                    value={TypeOfUser.REFERRAL_USER}
                    checked={typeOfUser === TypeOfUser.REFERRAL_USER}
                    className="sr-only"
                    disabled={id ? true : false}
                  />
                  <span className="flex flex-col text-md">
                    <span className="font-bold">
                      {t('ec_flow_texts.referral')}
                    </span>
                    <span className="font-medium text-gray-3">
                      {t('ec_flow_texts.invited')}
                    </span>
                  </span>
                </label>
              </div>
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        name: 'ecFlow',
        className: 'my-4',
        children: [
          {
            name: `header`,
            type: 'input',
            uiOptions: {
              label: t('ec_flow_texts.header'),
              labelClassName: 'text-sm text-grey-1',
              inputType: 'text',
              testId: 'header',
            },
          },
          {
            name: `text`,
            type: 'custom',
            customControlType: 'quillEditor',
            uiOptions: {
              label: t('ec_flow_texts.text'),
              labelClassName: 'text-sm text-grey-1',
              testId: 'text',
              visible: () =>
                currentScreenName !== ECFlowScreensType.QUESTIONNAIRE &&
                currentScreenName !== ECFlowScreensType.HAPPINESS_CHECK,
            },
            maxChars: 150,
          },
          {
            name: 'termsAndConditions',
            type: 'input',
            uiOptions: {
              label: t('ec_flow_texts.termsAndConditions'),
              labelClassName: 'text-md text-grey-1',
              inputType: 'text',
              testId: 'termsAndConditions',
              visible: () =>
                currentScreenName === ECFlowScreensType.QUESTIONNAIRE,
            },
          },
          {
            name: 'termsAndConditionsEndUser',
            type: 'input',
            uiOptions: {
              label:
                TypeOfUser.END_USER === typeOfUser
                  ? t('ec_flow_texts.termsAndConditionsEndUser')
                  : t('ec_flow_texts.termsAndConditionsReferral'),
              labelClassName: 'text-md text-grey-1',
              inputType: 'text',
              testId: 'termsAndConditionsEndUser',
              visible: () =>
                currentScreenName === ECFlowScreensType.QUESTIONNAIRE,
            },
          },
          {
            name: 'termsAndConditionsReferral',
            type: 'input',
            uiOptions: {
              label: t('ec_flow_texts.termsAndConditionsMarketing'),
              labelClassName: 'text-md text-grey-1',
              inputType: 'text',
              testId: 'termsAndConditionsReferral',
              visible: () =>
                currentScreenName === ECFlowScreensType.QUESTIONNAIRE,
            },
          },
          {
            name: 'termsAndConditionsSummary',
            type: 'input',
            uiOptions: {
              label: t('ec_flow_texts.termsAndConditionsSummary'),
              labelClassName: 'text-md text-grey-1',
              inputType: 'text',
              testId: 'termsAndConditionsSummary',
              visible: () =>
                currentScreenName === ECFlowScreensType.QUESTIONNAIRE,
            },
          },
        ],
      },
      {
        render: () => (
          <CampaignButtons
            submitLabel={t('next')}
            saveDraftLabel={t('save')}
            disableSubmit={
              pathname.split('/').pop() === ECFlowScreensType.EXPIRED
            }
            disableSaveDraft={true}
          />
        ),
      },
    ],
    [
      props.campaign.referralCampaign,
      currentScreenName,
      typeOfUser,
      id,
      navigate,
      pathname,
      t,
    ],
  )

  useEffect(() => setActiveStep(10), [setActiveStep])

  const nextScreenIndex =
    Object.values(ECFlowScreensType).findIndex(
      (value) => value === currentScreenName,
    ) + 1

  let nextScreenName = Object.values(ECFlowScreensType)[nextScreenIndex]

  if (
    nextScreenName === ECFlowScreensType.INVITE &&
    (!props.campaign.referralCampaign ||
      typeOfUser === TypeOfUser.REFERRAL_USER)
  ) {
    nextScreenName = Object.values(ECFlowScreensType)[nextScreenIndex + 1]
  }

  const onSubmit: SubmitHandler<ECFlowTextsFormType> = (
    data: Record<string, unknown>,
    e,
  ) => {
    const buttonName = (
      (e?.nativeEvent as SubmitEvent) || undefined
    )?.submitter?.getAttribute('name')
    createECFlowTexts({
      variables: {
        ecFlowTexts: {
          ecFlowTexts: {
            ...campaign.ecFlowTexts,
            [typeOfUser ?? '']: {
              ...(campaign.ecFlowTexts as any)?.[typeOfUser ?? ''],
              [currentScreenName]: { nl: data },
            },
          },
          id: campaign?.id ?? id,
        },
      },
      onCompleted() {
        if (buttonName === 'next') {
          let nextStep = nextScreenName
          if (
            props.campaign.happinessCheck === null &&
            nextScreenName === ECFlowScreensType.HAPPINESS_CHECK
          ) {
            nextStep = ECFlowScreensType.SUCCESS
          }
          navigate(`/campaigns/ec-flow/${id}/${typeOfUser}/${nextStep}`)
        }
      },
    })
  }

  return <AlphaForm fields={ecFlowForm} values={values} onSubmit={onSubmit} />
}
