import { TypeOfUser } from '../../../server/src/schema/ec-flow-texts.schema'

export function userType(userType: string) {
  return userType.includes(TypeOfUser.END_USER)
    ? TypeOfUser.END_USER
    : TypeOfUser.REFERRAL_USER
}

export function endUserType(referral: boolean) {
  return referral ? TypeOfUser.REFERRAL_USER : TypeOfUser.END_USER
}
