import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormProps } from '@sourcelabbg/form/lib'
import { CustomShadcnSelect } from './custom-types'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'

export type SelectCreatableOption = {
  label: string
  value: string
  data?: unknown
  disabled?: boolean
  default?: boolean
}

export type SelectSetter = (selected: SelectCreatableOption[]) => void

export type CustomSelectProps = {
  field: CustomShadcnSelect
  formProps: FormProps
}

export function CustomShadcnSelectComponent({
  field,
  formProps,
}: CustomSelectProps) {
  const [options, setOptions] = useState<SelectCreatableOption[]>(field.options)

  const CustomOption = (props: { setValue: (value: string) => void }) => {
    const [value, setValue] = useState('')
    const onAdd = () => {
      if (!value) return
      const newValue = {
        label: value,
        value: value,
      }
      setOptions((prevOptions) => [...(prevOptions || []), newValue])
      props.setValue(value)
      setValue('')
    }
    return (
      <div className="px-8 flex w-full" onKeyDown={(e) => e.stopPropagation()}>
        <input
          type="text"
          value={value}
          placeholder="Start typing custom here..."
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              onAdd()
            }
          }}
          className="text-sm my-2 focus:outline-none focus:ring-0 w-full"
        />
        <button
          className="bg-ui1 border rounded-lg my-0 py-0 h-6 mt-1 px-2 text-sm"
          onClick={() => onAdd()}
        >
          Add
        </button>
      </div>
    )
  }

  return (
    <Controller
      control={formProps.control}
      name={field.name}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <Select onValueChange={onChange} value={value}>
            <SelectTrigger ref={ref}>
              <SelectValue placeholder={field.uiOptions?.placeholder} />
            </SelectTrigger>

            <SelectContent>
              {options.map((option) => (
                <SelectItem
                  key={option.value}
                  value={option.value}
                  accessKey="false"
                >
                  {option.label}
                </SelectItem>
              ))}

              {field.creatable && (
                <CustomOption
                  setValue={(newValue) => {
                    setOptions([
                      ...options,
                      { value: newValue, label: newValue },
                    ])
                  }}
                />
              )}
            </SelectContent>
          </Select>
        )
      }}
    />
  )
}
