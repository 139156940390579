import { useMutation, useQuery } from '@apollo/client'
import cx from 'classnames'
import { useMemo, useState } from 'react'

import { Campaign } from '@/gql'
import {
  FileArrowDown,
  Files,
  SpinnerGap,
  PaperPlaneTilt,
  FolderSimpleUser,
  PencilLine,
} from '@phosphor-icons/react'
import { addHours, format, parseISO } from 'date-fns'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PrintFileDownloadModal } from '../../components/print-file-download'
import { stepRoutes } from '../../components/progress-bar'
import PRINT_FILES_DOWNLOAD_MUTATION from '../../graphql/campaign/mutations/print-files-download'
import CAMPAIGNS_QUERY from '../../graphql/campaign/queries/list'
import EXECUTE_CAMPAIGN_MUTATION from '@/graphql/campaign/mutations/execute-campaign'
import Tooltip from '@/components/tooltip'
import DeleteCampaignModal from '../../components/delete-campaign-modal'
import CAMPAIGN_GET from '@/graphql/campaign/queries/get'

enum Status {
  DONE = 'done',
  IN_PROGRESS = 'in_progress',
  UPCOMING = 'upcoming',
  DRAFT = 'draft',
}

export type CampaignTableItem = {
  id: string
  name: string
  type: string
  isDraft: boolean
  startDate: string
  endDate: string
  status: Status
  claimedGifts: string
  updatedAt: string
  completedStep: number
  printFileDownloadLink: string
  printFilePassword: string
}

export default function List() {
  const { data, loading } = useQuery(CAMPAIGNS_QUERY, {
    fetchPolicy: 'network-only',
  })
  const [printFilesDownloadMutation] = useMutation(
    PRINT_FILES_DOWNLOAD_MUTATION,
    {
      refetchQueries: () => [{ query: CAMPAIGNS_QUERY, variables: {} }],
    },
  )
  const [executeMailerCampaign] = useMutation(EXECUTE_CAMPAIGN_MUTATION, {})

  const [loadingExecute, setLoadingExecute] = useState<string>()
  const { data: loadedCampaign } = useQuery(CAMPAIGN_GET, {
    variables: { id: loadingExecute },
    skip: !loadingExecute,
    pollInterval: 5000,
  })
  const navigate = useNavigate()

  const { t } = useTranslation()

  if (loadedCampaign?.campaign?.printFilesLink) setLoadingExecute(undefined)

  const campaignStatus = (start: Date, end: Date): Status | undefined => {
    const now = new Date()
    const startDate = new Date(start)
    const endDate = new Date(end)

    if (now > endDate) return Status.DONE
    if (startDate > now && now < endDate) return Status.IN_PROGRESS
    if (now > startDate) return Status.UPCOMING
  }

  const campaigns = useMemo(
    () =>
      data?.campaigns?.map((campaign: Campaign) => {
        const status = campaign.isDraft
          ? Status.DRAFT
          : campaignStatus(campaign.startDate, campaign.endDate)
        return {
          id: campaign.id,
          name: campaign.name,
          type: campaign?.typeOfCampaign,
          isDraft: campaign.isDraft,
          startDate: format(parseISO(campaign.startDate), 'dd/MM/yyyy'),
          endDate: format(parseISO(campaign.endDate), 'dd/MM/yyyy'),
          status,
          claimedGifts: '',
          updatedAt: campaign.updatedAt,
          completedStep: campaign.completedStep,
          printFileDownloadLink: campaign.printFilesLink,
          printFilePassword: campaign.printPassword,
        }
      }),
    [data],
  )

  const statusClassName = (status: Status) => {
    switch (status) {
      case Status.DONE:
        return 'bg-green-50 text-green-700'
      case Status.IN_PROGRESS:
        return 'bg-violet-30 text-marine-800'
      case Status.UPCOMING:
        return 'bg-marine-300 text-black-4'
      case Status.DRAFT:
        return 'bg-ui1 text gray-2'
    }
  }

  const rowContentClassName = 'px-5 py-4 text-sm text-gray-1'
  const tableHeadersClassName =
    'whitespace-nowrap px-5 py-4 text-left text-sm font-semibold text-gray-3 uppercase'

  if (loading) return <div>{t('loading')}</div>

  const generatePrintFiles = async (campaignId: string) => {
    printFilesDownloadMutation({
      variables: { campaignId },
    })
  }

  return (
    <div className="bg-ui1 p-8 h-full">
      <div className="p-8 sm:rounded-lg bg-white overflow-scroll">
        <h1 className="font-bold text-4xl pb-10 leading-none mb-0">
          {t('campaign_list.campaign_list')}
        </h1>
        <div className="border sm:rounded-lg overflow-auto">
          <table className="w-full table-auto">
            <thead className="bg-ui1">
              <tr>
                <th scope="col" className={tableHeadersClassName}>
                  {t('campaign_list.campaign_name')}
                </th>
                <th scope="col" className={tableHeadersClassName}>
                  {t('campaign_list.start_date')}
                </th>
                <th scope="col" className={tableHeadersClassName}>
                  {t('campaign_list.end_date')}
                </th>
                <th scope="col" className={tableHeadersClassName}>
                  {t('campaign_list.status')}
                </th>
                <th scope="col" className={tableHeadersClassName}>
                  {t('campaign_list.claimed_gifts')}
                </th>
                <th scope="col" className={tableHeadersClassName}>
                  ACTIONS
                </th>
                <th scope="col" className={tableHeadersClassName}>
                  CAMPAIGN DATA
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {campaigns?.map((campaign: CampaignTableItem) => {
                return (
                  <tr key={campaign.id}>
                    <td className={rowContentClassName}>{campaign.name}</td>
                    <td className={rowContentClassName}>
                      {campaign.startDate}
                    </td>
                    <td className={rowContentClassName}>{campaign.endDate}</td>
                    <td className={rowContentClassName}>
                      <span
                        className={cx(
                          'whitespace-nowrap rounded-full px-2.5 text-sm',
                          statusClassName(campaign.status),
                        )}
                      >
                        {t(`campaign_list.${campaign.status}`)}
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-6 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                      {campaign.claimedGifts && (
                        <a
                          href="#"
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          {campaign.claimedGifts}
                          <span className="sr-only">, {campaign.id}</span>
                        </a>
                      )}
                    </td>
                    <td className={rowContentClassName}>
                      <div className="inline-grid grid-cols-2 gap-2 align-middle w-48">
                        <div>
                          {(new Date() <
                            addHours(new Date(campaign.updatedAt), 24) ||
                            campaign.isDraft) && (
                            <button
                              onClick={() =>
                                navigate(
                                  stepRoutes(
                                    campaign.completedStep,
                                    campaign.id,
                                  ),
                                )
                              }
                              className="text-sm flex flex-row gap-3 items-center border rounded-md px-1"
                            >
                              <PencilLine size={16} fill="#490091" />
                              {t('campaign_list.edit')}
                            </button>
                          )}
                        </div>
                        <div>
                          <DeleteCampaignModal campaignToDelete={campaign} />
                        </div>
                      </div>
                    </td>
                    <td className={rowContentClassName}>
                      <div className="inline-grid grid-cols-2 gap-2 align-middle ">
                        <div>
                          {campaign.printFileDownloadLink && (
                            <ShowDownloadModal campaign={campaign} />
                          )}

                          {!campaign.printFileDownloadLink &&
                            campaign.completedStep == 9 &&
                            campaign.type === 'offline' &&
                            (loadingExecute === campaign.id ? (
                              <SpinnerGap
                                size={24}
                                className="text-primary animate-spin"
                              />
                            ) : (
                              <Tooltip
                                child={
                                  <Files
                                    size={24}
                                    className="text-primary cursor-pointer"
                                    onClick={() => {
                                      setLoadingExecute(campaign.id)
                                      generatePrintFiles(campaign.id)
                                    }}
                                  />
                                }
                                text={t('tooltips.generate_files')}
                              />
                            ))}

                          {campaign.isDraft &&
                            campaign.type === 'online' &&
                            campaign.completedStep === 9 &&
                            (loadingExecute === campaign.id ? (
                              <SpinnerGap
                                size={24}
                                className="text-primary animate-spin"
                              />
                            ) : (
                              <Tooltip
                                child={
                                  <PaperPlaneTilt
                                    size={24}
                                    className="text-primary cursor-pointer"
                                    onClick={() => {
                                      executeMailerCampaign({
                                        variables: { campaignId: campaign.id },
                                        onCompleted: () =>
                                          setLoadingExecute(undefined),
                                      })
                                      setLoadingExecute(campaign.id)
                                    }}
                                  />
                                }
                                text={t('tooltips.send_email')}
                              />
                            ))}
                        </div>
                        <div>
                          {!campaign.isDraft && (
                            <a href={`/data/${campaign.id}`} target="_blank">
                              <Tooltip
                                child={
                                  <FolderSimpleUser
                                    size={24}
                                    className="text-primary cursor-pointer"
                                  />
                                }
                                text={t('tooltips.download_user_data')}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function ShowDownloadModal({ campaign }: { campaign: CampaignTableItem }) {
  const { t } = useTranslation()
  const [showPrintFileDownloadModal, setShowPrintFileDownloadModal] =
    useState(false)

  const portal = useMemo(() => {
    if (!showPrintFileDownloadModal) return <></>
    return createPortal(
      <PrintFileDownloadModal
        downloadLink={campaign.printFileDownloadLink}
        password={campaign.printFilePassword}
        onClose={() => setShowPrintFileDownloadModal(false)}
      />,
      document.body,
    )
  }, [campaign, showPrintFileDownloadModal])

  return (
    <>
      {portal}
      <Tooltip
        child={
          <FileArrowDown
            className="text-primary cursor-pointer"
            size={24}
            onClick={() =>
              setShowPrintFileDownloadModal(!showPrintFileDownloadModal)
            }
          />
        }
        text={t('tooltips.download_creative')}
      />
    </>
  )
}
